import React, { useEffect, useState } from "react";
import Platform from "../components/platform/Platform";
import AboutUs from "../components/about-us/AboutUs";
import { ancharTop } from "../assets";
import WorkingSteps from "../components/working-steps/WorkingSteps";

function HowItWorks() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Platform />
      <AboutUs />
      <WorkingSteps />

      {showButton && (
        <img
          src={ancharTop}
          alt=""
          className="scrollToTop"
          onClick={() => window.scrollTo({ top: 0 })}
        />
      )}
    </div>
  );
}

export default HowItWorks;
