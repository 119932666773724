import React from "react";
import "./_footer.scss";
import { logo } from "../../assets";
import { Icon } from "@iconify/react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { scroller } from "react-scroll";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToSection = (section, offset = 0) => {
    navigate("/");
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        offset: offset,
      });
    }, 100);
  };

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const handleContactClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="footer">
        <div className="footer-inner">
          <div className="footer-top">
            <div className="left">
              <h3>
                Reach out for support, or drop feedback.
                <br className="dev-break" /> We’d love to hear from you.
              </h3>
            </div>

            <div className="right">
              <button className="contact-btn" onClick={handleContactClick}>
                Contact Us
              </button>
            </div>
          </div>

          <div className="line-verticle"></div>

          <div className="footer-bottom">
            <div className="ist">
              <img
                src={logo}
                alt=""
                className="footer-logo"
                onClick={handleLogoClick}
                style={{ cursor: "pointer" }}
              />
              <div className="social_icons">
                <Link to="" className="icons">
                  <FaFacebook className="icon" style={{ cursor: "pointer" }} />
                </Link>
                <Link to="" className="icons">
                  <Icon
                    icon="pajamas:twitter"
                    width="16"
                    height="16"
                    color="white"
                    style={{ cursor: "pointer" }}
                  />
                </Link>
                <Link to="" className="icons">
                  <FaInstagram className="icon" style={{ cursor: "pointer" }} />
                </Link>
                <Link to="" className="icons">
                  <FaYoutube className="icon" style={{ cursor: "pointer" }} />
                </Link>
              </div>
              <p>© 2024 Deuces AI, LLC.</p>
            </div>

            <div className="center">
              <div className="second">
                <h6>Company</h6>
                <div>
                  <Link to='/terms-and-conditions'>
                    Terms & Conditions
                  </Link>
                  <Link to='/privacy-policy'>
                    Privacy Policy
                  </Link>
                  <Link to='/faqs'>
                    FAQs
                  </Link>
                </div>
              </div>
              <div className="third">
                <h6>Product</h6>
                <div>
                  <NavLink to="/how-it-works" onClick={() => navigateToSection("platform", -200)}>How it works</NavLink>
                  <NavLink to="/pricing">Pricing</NavLink>
                </div>
              </div>
              <div className="forth">
                <h6>Contact</h6>
                <div>
                  <a href={process.env.REACT_APP_DASHBOARD_URL}>
                    Login
                  </a>
                  <NavLink to="/contact">Contact Us</NavLink>
                </div>
              </div>
            </div>

            <div className="fifth">
              <h6 onClick={handleContactClick}>Get in Touch</h6>
              <div className="addresses">
                <div>
                  <Icon
                    icon="pajamas:location"
                    width="24px"
                    height="24px"
                    style={{ color: "#8762e9" }}
                  />
                  <p>
                    8819 Ohio St. South Gate,
                    <br /> CA 90280
                  </p>
                </div>
                <div>
                  <Icon
                    icon="carbon:email"
                    width="24px"
                    height="24px"
                    style={{ color: "#8762e9" }}
                  />
                  <p>hello@deuces.ai</p>
                </div>
                <div>
                  <Icon
                    icon="material-symbols:call"
                    width="24px"
                    height="24px"
                    style={{ color: "#8762e9" }}
                  />
                  <p>+1 386-688-3295</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
