import React from "react";
import { Modal } from "antd";
import { Icon } from "@iconify/react";
import "./_template_modal.scss";
import { templatesPlan } from "../../constants";

const TemplateModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="purchase-modal"
        centered={true}
        footer={null}
        width="60vw"
      >
        <h5 className="text-center mt-4">
          Maximize your campaign's potential by adding extra AI-generated
          templates.
        </h5>

        <div className="purchasemodal-content pt-5 h-center gap-3">
          {templatesPlan.map((item) => (
            <div
              key={item.id}
              className={`purchase-card ${
                item.name === "Pro"
                  ? "enterprise-card text-white"
                  : "text-darkpurple"
              }`}
            >
              {/* <h4>{item.name}</h4> */}
              <span className="v-center gap-2  segoe-family">
                <Icon
                  color={item.name === "Enterprise" ? "#fff" : "#28104E"}
                  icon="entypo:check"
                  width={16}
                  height={16}
                  className="mt-2"
                />
                <span className="d-flex gap-2">
                  <h2 className="small">{item.templates}</h2>
                  <h5 className="mb-2">Templates</h5>
                </span>
              </span>
              <div className="amt-txt v-center">
                <span className="d-flex w-100 justify-content-cente r">
                  <label className="large">$</label>
                  <h4>{item.price}</h4>
                </span>
                {/* <label className="large">/month</label> */}
              </div>
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/subscription`}>
                <button
                  className="text-darkpurple"
                  // onClick={() => {
                  //   navigate("/settings");
                  // }}
                >
                  Purchase
                </button>
              </a>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default TemplateModal;
