import React, { useState } from "react";
import "./_get_started.scss";
import { Curve, sparkles, whiteStars } from "../../assets";

const GetStarted = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div className="element_top">
        <h1>
          Embrace the future of personalized communication with just a click. <br />
          Try now!
        </h1>

        <div className="main">
          <img src={Curve} className="main-layer" alt="" />

          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/subscription`}
            className="maininner"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="inner">
              {isHovered ? (
                <img src={whiteStars} alt="" />
              ) : (
                <img src={sparkles} alt="" />
              )}
              <span>Free 7 Day Trial</span>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
