import React, { useState } from "react";
import "./hero.scss";
import { whiteStars, sparkles } from "../../assets";

const Hero = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="herosection">
      <div className="herosection-primary">
        <div className="herosection-content">
          <div className="primary-heading">
            <h1>
              Personalized outreach
              <br />
              at scale.
            </h1>
          </div>

          <div className="secondary-heading">
            <h2 className="Deucesai text-center">
              AI Email Marketing Platform
              <br />
            </h2>
          </div>

          <a href={`${process.env.REACT_APP_DASHBOARD_URL}/dashboard`}>
            <button
              className="button-free"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span>
                {isHovered ? (
                  <img src={sparkles} alt="" />
                ) : (
                  <img src={whiteStars} alt="" />
                )}{" "}
                Get Started
              </span>
            </button>
          </a>
        </div>
      </div>

      <div className="left_box_shadow"></div>
    </div>
  );
};

export default Hero;
