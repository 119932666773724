import React, { useState } from "react";
import "./feature.scss";
import { featureBullet } from "../../constants";

const Features = () => {
  const [hoveredVideo, setHoveredVideo] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredVideo(id);
  };

  const handleMouseLeave = () => {
    setHoveredVideo(null);
  };

  const toggleFullScreen = (videoId) => {
    const videoElement = document.getElementById(`video-${videoId}`);

    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      }
    }
  };

  return (
    <div className="feature-section">
      <div className="feature-content">
        <div className="title">
          <h4>Explore Features</h4>
        </div>

        <div className="features-wrapper">
          {featureBullet.map((item) => (
            <div className="features-item" key={item.id} name={item.name}>
              {/* Shadow */}
              <div
                className={`bg-shadow`}
                style={{
                  left: item.id % 2 === 0 ? "0%" : "",
                  right: item.id % 2 !== 0 ? "0%" : "",
                }}
              ></div>

              {/* Content box */}
              <div className="content">
                <div className="title">
                  <h6>{item.title}</h6>
                </div>

                {item.gradientText && (
                  <p>
                    <span>{item.gradientText}</span>
                  </p>
                )}

                <p>{item.descriptions}</p>
              </div>

              {/* Image box */}
              <div className="rightside">
                <div
                  className="rightside-content"
                  style={{ position: "relative", width: "fit-content" }}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <button
                    className="Aibutton"
                    onClick={() => toggleFullScreen(item.id)}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 2,
                      display: hoveredVideo === item.id ? "block" : "none",
                    }}
                  >
                    Fullscreen
                  </button>

                  <video
                    id={`video-${item.id}`}
                    className="multivideo"
                    src={item.src}
                    muted
                    autoPlay
                    loop
                    style={{ display: "block", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
