import React, { useState, useRef, useEffect } from "react";
import "./navbar.scss";
import { logo } from "../../assets";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { sparkles } from "../../assets";
import { whiteStars } from "../../assets";
import { scroller } from "react-scroll";
import { GrMenu } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import { dropdownItems, navLinks } from "../../constants";

const Navbar = () => {
  const [showNavExternal3, setShowNavExternal3] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navbarRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleClick = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setShowNavExternal3(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigateToHomeSection = (section) => {
    navigate("/");
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
      });
    }, 100);
    setShowNavExternal3(false);
  };

  return (
    <nav
      ref={navbarRef}
      className="navbar navbar-expand-lg navbar-light navbar-main"
    >
      <div className="container-fluid navbar-container">
        <NavLink to="/" className="navbar-brand" onClick={handleClick}>
          <img src={logo} alt="logo" />
        </NavLink>



        <div
          className={`collapse navbar-collapse px-2 ${showNavExternal3 ? "show" : ""
            }`}
        >
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navbar-ul">
            <li className="nav-item dropdown navbaritems">
              <Link
                className="nav-link"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown}
                aria-expanded={isDropdownOpen ? "true" : "false"}
              >
                Features
                <Icon
                  icon="iconamoon:arrow-down-2"
                  width="30"
                  height="30"
                  style={{ color: "#3f1b78", marginLeft: "0px" }}
                />
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {dropdownItems.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => navigateToHomeSection(item.section)}
                  >
                    <Link to="#" className="dropdown-item">
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {navLinks.map((link, index) => (
              <li key={index} className="nav-item navbaritems">
                <NavLink className="nav-link" to={link.path}>
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>

          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/subscription`}
            className="maininner-new"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="inner">
              {!isHovered ? (
                <img src={whiteStars} alt="star" />
              ) : (
                <img src={sparkles} alt="sparkles" />
              )}
              <span>Free 7 Day Trial</span>
            </div>
          </a>
        </div>

        <div className="d-flex v-center gap-3 ">
          <a href={`${process.env.REACT_APP_DASHBOARD_URL}/`}>

            <button className="global-btn">
              Login
            </button>
          </a>
          <span className="toggle-icon">
            {showNavExternal3 ? (
              <IoMdClose
                size={24}
                onClick={() => setShowNavExternal3((prev) => !prev)}
              />
            ) : (
              <GrMenu
                size={24}
                onClick={() => setShowNavExternal3((prev) => !prev)}
              />
            )}
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
