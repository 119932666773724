import {
  access,
  advanceAnalytics,
  aiTemplate,
  analytics,
  campaign,
  emailPreview,
  hyperPersonalized,
  login,
  multipleTemplates,
  personalize,
  preview,
  unlimitedEmailSending,
} from "../assets";

// NavLinks
export const navLinks = [
  { path: "/how-it-works", label: "How it works" },
  { path: "/pricing", label: "Pricing" },
  { path: "/contact", label: "Contact Us" },
];

// Sub Menu
export const dropdownItems = [
  {
    section: "HyperPersonalized",
    label: "Hyper Personalized Sections of AI input",
  },
  { section: "AIGeneratedTemplates", label: "AI generated Templates" },
  {
    section: "Emailpreviewbeforesending",
    label: "Email preview before sending",
  },
  {
    section: "MultiTemplateAICampaigns",
    label: "Multi-Template AI Campaigns",
  },
  { section: "Advancedanalytics", label: "Advanced analytics" },
  { section: "Unlimitedemailsending", label: "Unlimited email sending" },
];

// FAQs
export const accordionData = [
  {
    accordionHeader: "How does it work?",
    accordionBody:
      "Come prepared with your own template and tell us what to fill in with our token double brackets, or let our AI generator create a template for you. When you get to the campaign section, all it takes is uploading a spreadsheet with static and dynamic content you’d like to craft the emails with. A single bracket { indicates standard variable replacements (e.g., name, company name) from the spreadsheet, and a double bracket indicates {{ dynamic content generation via  ChatGPT for more personalized sections of the email. Don’t worry-you have the opportunity to  preview your emails before sending a campaign out.",
  },
  {
    accordionHeader:
      "What makes Deuces.ai different than other email marketing tools?",
    accordionBody:
      "With traditional bulk email senders, you get the exposure of a wide audience, but you sacrifice personalization which is arguably the most important part of outreach. We understand the importance of connecting we are a species of community. This is why Deuces offers the best of both worlds: the scalability of mass outreach and the impact of individualized communication, all powered by AI.",
  },
  {
    accordionHeader: "Does Deuces.ai offer integration with CRM systems?",
    accordionBody:
      "Yes, Deuces.ai integrates with CRM systems, streamlining your workflow by automatically syncing contact information and enabling seamless campaign management directly from your CRM.",
  },
  {
    accordionHeader:
      "Can I purchase additional AI-generated templates beyond what my plan includes?",
    accordionBody:
      "Yes, you can enhance your email campaigns by purchasing additional AI-generated templates beyond those included in your subscription plan. We offer flexible packs to meet your needs,ranging from packs of 5-100.",
  },
  {
    accordionHeader: "How do I get started?",
    accordionBody:
      "Click sign up to connect your gmail inbox. From there you can view saved templates, upload spreadsheets, and run campaigns",
  },
  {
    accordionHeader: "How do I run a multi-template campaign?",
    accordionBody:
      "Our pro and premium plans give you the option to select multiple ai generated templates per campaign. Feed up to four text boxes with information about your campaign goals, target demographics, and preferred messaging tone, and select your perfect templates. Don’t worry-you can regenerate the resulting ai templates as much as you’d like to make sure you’re satisfied with the templates generated.After your campaign is live, utilize Deuces.ai's analytics tools to track the performance of each template.This data is invaluable for understanding what resonates with your audience and can inform future campaigns, enabling continuous improvement and optimization.",
  },
  {
    accordionHeader:
      "What are the benefits of running a campaign with multiple templates?",
    accordionBody:
      "By comparing the performance metrics (open rates, click-through rates, conversions, etc.) of multiple templates, you gain insights into what resonates best with your audience. Diversifying your email content can also help avoid spam filters, as too many identical emails might be flagged by email service providers. Varied templates ensure your emails are more likely to reach the inbox.",
  },
];

// Features
export const featureBullet = [
  {
    id: 1,
    name: "HyperPersonalized",
    title: "Hyper Personalized Sections of AI input",
    descriptions:
      "Deuces's ability to automatically generate personalized content for different sections of an email allows you to not only reach more prospects in less time, but also connect with them.",
    src: hyperPersonalized,
  },
  {
    id: 2,
    name: "AIGeneratedTemplates",
    title: "AI generated Templates",
    descriptions:
      "Simply input your personal preferences for your campaign. Our AI generator will analyze your inputs - such as campaign goals, target demographics, and preferred messaging tone - to generate highly engaging email templates.",
    src: aiTemplate,
  },
  {
    id: 3,
    name: "Emailpreviewbeforesending",
    title: "Email preview before sending",
    descriptions:
      "Get a final look at your emails exactly as they will appear to your recipients. Simply scroll through and make last minute changes.",
    src: emailPreview,
  },
  {
    id: 4,
    name: "MultiTemplateAICampaigns",
    title: "Multi-Template AI Campaigns",
    gradientText: `Pro + Premium Feature`,
    descriptions: `Introduce variety into your campaigns. Select 2 or more ai generated templates to be randomly distributed to your list.  Gain insights into what resonates best.`,
    src: multipleTemplates,
  },
  {
    id: 5,
    name: "Advancedanalytics",
    title: "Advanced Analytics",
    gradientText: `Pro + Premium Feature`,
    descriptions: `A/B Testing`,
    src: advanceAnalytics,
  },
  {
    id: 6,
    name: "Unlimitedemailsending",
    title: "Unlimited Email Sending",
    gradientText: `Premium Feature`,
    descriptions: `Send as many emails as you need without any restrictions on volume, giving you the most flexibility and scalability for your email marketing campaigns.`,
    src: unlimitedEmailSending,
  },
];

// working steps
export const workingSteps = [
  {
    id: 1,
    title: "Sign up",
    description: "Connect your gmail account and set up your profile.",
    img: login,
  },
  {
    id: 2,
    title: "Grant Access",
    description: "Click “Select all” to give Deuces permission to access your Google Drive files, Google Sheets spreadsheets, and send emails.",
    img: access,
  },
  {
    id: 2,
    title: "Create Campaign",
    description:
      "Input your personal preferences into our ai generator for tailored templates. Set clear objectives for what you want each campaign to achieve.",
    img: campaign,
  },
  {
    id: 3,
    title: "Personalize",
    description:
      "Use single brackets for simple data swaps and double brackets for dynamic, personalized content via Chat GPT 4.0.",
    img: personalize,
  },
  {
    id: 4,
    title: "Preview and Perfect",
    description:
      "Before any emails go out, swipe through each one to make any necessary adjustments.",
    img: preview,
  },
  {
    id: 5,
    title: "Track and Optimize",
    description:
      "Monitor real-time metrics through a comprehensive analytics dashboard. Adjust your strategies based on performance data to continually enhance engagement.",
    img: analytics,
  },
];

// monthly prices
export const monthlyPricingPlans = [
  {
    id: 1,
    name: "Base",
    bullets: [
      {
        id: 1,
        enable: true,
        bullet: "5,000 Emails/Month",
      },
      {
        id: 2,
        enable: true,
        bullet: "10 AI Templates/Month",
      },
      {
        id: 3,
        enable: true,
        bullet: "1 AI Written Section/Campaign",
      },
      {
        id: 4,
        enable: true,
        bullet: "200 Word Length Limit/ Email",
      },
      {
        id: 5,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Basic insights on opens and clicks
          </span>
        ),
      },
      {
        id: 6,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>Standard Email Support</span>
        ),
      },
      {
        id: 7,
        enable: false,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Multi Template AI Campaigns
          </span>
        ),
      },
      {
        id: 8,
        enable: false,
        bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
      },
    ],
    price: 50,
    button: "Get Started",
  },
  {
    id: 2,
    name: "Pro",
    bullets: [
      {
        id: 1,
        enable: true,
        bullet: "15,000 Emails/Month",
      },
      {
        id: 2,
        enable: true,
        bullet: "20 AI Templates/Month",
      },
      {
        id: 3,
        enable: true,
        bullet: "3 AI Written Sections/Campaign",
      },
      {
        id: 4,
        enable: true,
        bullet: "400 Word Length Limit/ Email",
      },
      {
        id: 5,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Advanced analytics insights on opens and clicks
          </span>
        ),
      },
      {
        id: 6,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>Standard Email Support</span>
        ),
      },
      {
        id: 7,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Multi Template AI Campaigns
          </span>
        ),
      },
      {
        id: 8,
        enable: true,
        bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
      },
    ],
    price: 75,
    button: "Get Started",
  },
  {
    id: 3,
    name: "Premium",
    bullets: [
      {
        id: 1,
        enable: true,
        bullet: "30,000 Emails/Month",
      },
      {
        id: 2,
        enable: true,
        bullet: "40 AI Templates/Month",
      },
      {
        id: 3,
        enable: true,
        bullet: (
          <>
            <label>Unlimited</label> AI Written Sections/Campaign
          </>
        ),
      },
      {
        id: 4,
        enable: true,
        bullet: (
          <>
            <label>Unlimited</label> Word Length Limit/ Email
          </>
        ),
      },
      {
        id: 5,
        enable: true,
        bullet: <span style={{ fontWeight: "bold" }}>Advanced Analytics</span>,
      },
      {
        id: 6,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>High Priority Support</span>
        ),
      },
      {
        id: 7,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Multi Template AI Campaigns
          </span>
        ),
      },
      {
        id: 8,
        enable: true,
        bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
      },
    ],
    price: 120,
    button: "Get Started",
  },
];

// annual prices
export const annuallyPricingPlans = [
  {
    id: 1,
    name: "Base",
    bullets: [
      {
        id: 1,
        enable: true,
        bullet: "5,000 Emails/Month",
      },
      {
        id: 2,
        enable: true,
        bullet: "10 AI Templates/Month",
      },
      {
        id: 3,
        enable: true,
        bullet: "1 AI Written Section/Campaign",
      },
      {
        id: 4,
        enable: true,
        bullet: "200 Word Length Limit/ Email",
      },
      {
        id: 5,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Basic insights on opens and clicks
          </span>
        ),
      },
      {
        id: 6,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>Standard Email Support</span>
        ),
      },
      {
        id: 7,
        enable: false,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Multi Template AI Campaigns
          </span>
        ),
      },
      {
        id: 8,
        enable: false,
        bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
      },
    ],
    price: 550,
    button: "Get Started",
  },
  {
    id: 2,
    name: "Pro",
    bullets: [
      {
        id: 1,
        enable: true,
        bullet: "15,000 Emails/Month",
      },
      {
        id: 2,
        enable: true,
        bullet: "20 AI Templates/Month",
      },
      {
        id: 3,
        enable: true,
        bullet: "3 AI Written Sections/Campaign",
      },
      {
        id: 4,
        enable: true,
        bullet: "400 Word Length Limit/ Email",
      },
      {
        id: 5,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Advanced analytics insights on opens and clicks
          </span>
        ),
      },
      {
        id: 6,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>Standard Email Support</span>
        ),
      },
      {
        id: 7,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Multi Template AI Campaigns
          </span>
        ),
      },
      {
        id: 8,
        enable: true,
        bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
      },
    ],
    price: 850,
    button: "Get Started",
  },
  {
    id: 3,
    name: "Premium",
    bullets: [
      {
        id: 1,
        enable: true,
        bullet: "30,000 Emails/Month",
      },
      {
        id: 2,
        enable: true,
        bullet: "40 AI Templates/Month",
      },
      {
        id: 3,
        enable: true,
        bullet: (
          <>
            <label>Unlimited</label> AI Written Sections/Campaign
          </>
        ),
      },
      {
        id: 4,
        enable: true,
        bullet: (
          <>
            <label>Unlimited</label> Word Length Limit/ Email
          </>
        ),
      },
      {
        id: 5,
        enable: true,
        bullet: <span style={{ fontWeight: "bold" }}>Advanced Analytics</span>,
      },
      {
        id: 6,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>High Priority Support</span>
        ),
      },
      {
        id: 7,
        enable: true,
        bullet: (
          <span style={{ fontWeight: "bold" }}>
            Multi Template AI Campaigns
          </span>
        ),
      },
      {
        id: 8,
        enable: true,
        bullet: <span style={{ fontWeight: "bold" }}>A/B testing</span>,
      },
    ],
    price: 1400,
    button: "Get Started",
  },
];

// Template plan
export const templatesPlan = [
  {
    id: 1,
    name: "Base",
    templates: 5,
    price: 3,
    button: "Purchase",
  },
  {
    id: 2,
    name: "Pro",
    templates: 15,
    price: 9,
    button: "Purchase",
  },
  {
    id: 3,
    name: "Enterprise",
    templates: 25,
    price: 15,
    button: "Purchase",
  },
];
