import React, { useEffect, useState } from "react";
import PricingCards from "../components/pricing-cards/PricingCards";
import TemplateModal from "../components/template_modal/TemplateModal";
import "./../style/components/_pricing.scss";
import { ancharTop, logo } from "../assets";

const Pricing = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 800) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div>
                <div className="pricing-main-conatiner">
                    <img src={logo} alt="logo" className="logo invisible" />

                    <div className="pricing-inner v-center flex-column">
                        <div className="pricing_title">
                            <h1>
                                The <span>Right Plan</span>
                                <br /> for Your Business
                            </h1>
                        </div>

                        <p className="mt-16 text-center">
                            <span>Deuces</span>{" "}
                            is the leading AI email marketing solution, trusted by thousands of
                            companies globally. Unlock the full potential of AI with
                            personalized email campaigns today.
                        </p>

                        <div className="v-center flex-column mt-5">
                            <PricingCards />
                        </div>
                    </div>

                    <div className="subscription-bottom h-center">
                        <button onClick={() => setIsModalOpen(true)}>
                            Purchase Templates
                        </button>
                    </div>

                    <TemplateModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                </div>
            </div>

            {showButton && (
                <img
                    src={ancharTop}
                    alt=""
                    className="scrollToTop"
                    onClick={() => window.scrollTo({ top: 0 })}
                />
            )}
        </>
    );
};

export default Pricing;
