import React from "react";
import "./platform.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import { sparkles } from "../../assets";

function Platform() {
  return (
    <section name="platform" className="platform-sec">
      <div className="top-head">
        <h1>
          <img src={sparkles} alt="" /> How it{" "}
          <span>works</span>
        </h1>
      </div>
      
      <div className="content">
        <div className="ist">
          <Icon
            icon="majesticons:login-half-circle"
            style={{ color: "#804ed0" }}
          />
          <p>Login</p>
        </div>
       
        <div className="center-line"></div>
        
        <div className="second">
          <Icon icon="ri:ai-generate" style={{ color: "#804ed0" }} />
          <p>Generate</p>
        </div>
        
        <div className="center-line"></div>
        
        <div className="ist">
          <Icon
            icon="material-symbols:stacked-email-rounded"
            style={{ color: "#804ed0" }}
          />
          <p>Email</p>
        </div>
      </div>
    </section>
  );
}

export default Platform;
