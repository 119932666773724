import React from "react";
import { annuallyPricingPlans, monthlyPricingPlans } from "../../constants";
import PlanCard from "./pricingCard";

const PricingCards = () => {
    const [on, setOnState] = React.useState(false);
    const toggle = () => setOnState((o) => !o);

    return (
        <>
            <div className="toogle-div v-center">
                <label className="elarge text-darkpurple">Bill Monthly</label>

                <button className={on ? "on" : "off"} on={on} onClick={toggle}>
                    <span className="pin" />
                </button>

                <label className="large text-darkpurple">Bill Annually</label>
            </div>

            <div className={`cards-wrapper`}>
                {on ? annuallyPricingPlans.map((item) => (
                    <PlanCard item={item} annual={true} />
                )) : monthlyPricingPlans.map((item) => <PlanCard item={item} />)}
            </div>
        </>
    );
};

export default PricingCards;
