import React, { useState } from "react";
import "./_faqs.scss";
import { FaMinus, FaPlus } from "react-icons/fa";
import { accordionData } from "../../constants";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="frequently_main container-fluid" name="faqs">
      <div className="container faq-container">
        <div className="frequently_left">
          <h1>
            <span>Frequently</span> Asked{" "}
            <br /> Questions
          </h1>
        </div>

        <div className="h-faq">
          {accordionData.map((item, index) => (
            <div className="accordion-item" key={index}>
              <div
                className="accordion-header"
                onClick={() => handleToggle(index)}
              >
                <h2
                  className={`accordion-question ${openIndex === index ? "active" : ""
                    }`}
                >
                  {item.accordionHeader}
                </h2>

                <span className="acc-icon">
                  {openIndex === index ? (
                    <FaMinus />
                  ) : (
                    <FaPlus />
                  )}
                </span>
              </div>

              <div
                className={`accordion-content`}
                style={{
                  maxHeight: openIndex === index ? "500px" : "0px",
                }}
              >
                <div className="accordion-body">
                  <p>{item.accordionBody}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
