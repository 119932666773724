import React from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Home from "./page/Home";
import Footer from "./components/footer/footer";
import Contact from "./page/Contact";
import HowItWorks from "./page/HowItWorks";
import Navbar from "./components/header/Navbar";
import Pricing from "./page/Pricing";
import PrivacyPolicy from "./page/PrivacyPolicy";
import TermsAndConditions from "./page/TermsAndConditions";
import FAQs from "./page/FAQs";

function App() {
  return (
    <div className="App">
      {/* Header */}
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/faqs" element={<FAQs />} />
      </Routes>

      {/* Footer */}
      <Footer />
    </div>
  );
}

    export default App;   
