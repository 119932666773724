import React, { useEffect, useState } from "react";
import Hero from "../components/hero/Hero";
import Features from "../components/features/Features";
import GetStarted from "../components/get-started/GetStarted";
import VideoFrame from "../components/video-frame/VideoFrame";
import FAQs from "../components/faqs/FAQs";
import { ancharTop } from "../assets";

const Home = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Hero />
      <VideoFrame />
      <Features />
      <FAQs />
      <GetStarted />
      
      {/* Go to top */}
      {showButton && (
        <img
          src={ancharTop}
          alt=""
          className="scrollToTop"
          onClick={() => window.scrollTo({ top: 0 })}
        />
      )}
    </div>
  );
};

export default Home;
