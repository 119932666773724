import React, { useEffect, useState } from 'react';
import { ancharTop } from '../assets';

const TermsAndConditions = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className='d-flex v-center h-center'>
                <div className="container privacy-policy">
                    <h1 className="display-4 mb-5 text-center">Terms & Conditions</h1>
                    <p className="text-muted text-center fw-bold">Effective Date: August 20, 2024</p>

                    <p className="lead mt-5">Welcome to Deuces! By accessing or using our services, you agree to comply with these terms. These Terms and Conditions govern your access to and use of our website and services.</p>

                    <h2 className="mt-5">Acceptance of Terms</h2>
                    <p>By accessing or using any part of our services, you confirm your acceptance of these Terms and Conditions. If you do not agree to all the terms stated, you must discontinue use immediately.</p>

                    <h2 className="mt-5">Use of Our Services</h2>
                    <p>When using our services, you agree to:</p>
                    <ul>
                        <li><strong>Compliance with Laws:</strong> You will use our services in compliance with all applicable laws and regulations, including email marketing laws (e.g., CAN-SPAM, GDPR), and Google API policies, specifically the <a href="https://developers.google.com/workspace/workspace-api-user-data-developer-policy#appropriate-access">Workspace API User Data and Developer Policy</a>. You agree to only send emails to recipients who have explicitly consented to receive </li>
                        <li><strong>Google API Compliance:</strong> You will ensure that any use of Google’s APIs complies with their policies, including appropriate access to and use of Gmail API data. You must not distribute spam or unsolicited commercial emails. All recipients of emails sent using our platform must have given explicit prior consent to receive such communications. Additionally, you agree to adhere to Google’s API restrictions on data handling, storage, and transmission as outlined <a href="https://developers.google.com/workspace/workspace-api-user-data-developer-policy#appropriate-access">here</a>.</li>
                        <li><strong>Account Security:</strong> You are responsible for safeguarding your account credentials and for any activities conducted under your account. Any unauthorized use of your account must be reported to us immediately.</li>
                        <li><strong>Prohibited Activities:</strong> You agree not to engage in any activities that could harm or interfere with our services, including but not limited to distributing malware, spamming, hacking, or violating Google’s API terms.</li>
                    </ul>

                    <h2 className="mt-5">Intellectual Property</h2>
                    <p>All content available on our website, including but not limited to text, graphics, logos, and images, is the exclusive property of Deuces.ai or its content suppliers and is protected by intellectual property laws. No part of this content may be reproduced, distributed, or transmitted without prior written permission.</p>

                    <h2 className="mt-5">Termination</h2>
                    <p>We reserve the right to terminate or suspend your access to our services at any time, without notice, for any reason, including if you breach these Terms and Conditions or fail to comply with Google’s API policies.</p>

                    <h2 className="mt-5">Limitation of Liability</h2>
                    <p>To the fullest extent permitted by law, Deuces shall not be liable for any damages arising out of your use of our services. This includes direct, indirect, incidental, and consequential damages.</p>

                    <h2 className="mt-5">Governing Law</h2>
                    <p>These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which Deuces.ai operates, without regard to its conflict of law principles.</p>

                    <h2 className="mt-5">Changes to These Terms</h2>
                    <p>We reserve the right to modify these Terms and Conditions at any time. Updates will be posted on this page, along with the date of revision. Continued use of our services following any changes signifies your acceptance of the updated terms.</p>

                    <h2 className="mt-5">Contact Us</h2>
                    <p>If you have any questions or concerns about these Terms and Conditions, please <a href="/contact">Contact us</a>.</p>
                </div>
            </div>

            {showButton && (
                <img
                    src={ancharTop}
                    alt=""
                    className="scrollToTop"
                    onClick={() => window.scrollTo({ top: 0 })}
                />
            )}
        </>
    );
}

export default TermsAndConditions;
