import React, { useEffect, useState } from "react";
import "./../style/components/_contact.scss";
import { ancharTop } from "../assets";
import contactUs from "../utils/contactUs";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        email: formData.email,
        message: formData.message,
      }
      const result = await contactUs(data)
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
      valid = false;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="contactcomp">
        <div className="contactUs-main">
          <h6 className="contact-heading">Contact us</h6>

          <div className="contact-wrapper">
            <div className="contact-left">
              <div className="left-content">
                <h5>Discover the Power of AI in Customer Support</h5>
              </div>
            </div>

            <div className="right-contact">
              <div className="right-content">
                <form onSubmit={handleSubmit}>
                  <div className="inputField-section">
                    <div className="leftside-field">
                      <span className="error" style={{ color: "red" }}>
                        {errors.firstName}
                      </span>
                      <input
                        type="text"
                        placeholder="First Name*"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="leftside-field">
                      <span className="error" style={{ color: "red" }}>
                        {errors.lastName}
                      </span>
                      <input
                        type="text"
                        placeholder="Last Name*"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="inputField-section">
                    <div className="leftside-field">
                      <span className="error" style={{ color: "red" }}>
                        {errors.email}
                      </span>
                      <input
                        type="email"
                        placeholder="Email*"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="inputField-section">
                    <div className="leftside-field">
                      <span className="error" style={{ color: "red" }}>
                        {errors.message}
                      </span>
                      <textarea
                        id="w3review"
                        name="message"
                        cols="50"
                        placeholder="Your message..."
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="inputField-section">
                    <div className="leftside-field">
                      <button type="submit">Send Request</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-shadow"></div>
      </div>

      {showButton && (
        <img
          src={ancharTop}
          alt=""
          className="scrollToTop"
          onClick={() => window.scrollTo({ top: 0 })}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Contact;
