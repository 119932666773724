import React, { useEffect, useState } from 'react';
import { accordionData } from '../constants';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { ancharTop } from '../assets';

const FAQs = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [showButton, setShowButton] = useState(false);

    const handleToggle = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className='d-flex v-center h-center'>
                <div className="container privacy-policy">
                    <h1 className="display-4 mb-5 text-center">Frequently Asked Questions</h1>

                    <div className="h-faq">
                        {accordionData.map((item, index) => (
                            <div className="accordion-item" key={index}>
                                <div
                                    className="accordion-header"
                                    onClick={() => handleToggle(index)}
                                >
                                    <h2
                                        className={`accordion-question ${openIndex === index ? "active" : ""
                                            }`}
                                    >
                                        {item.accordionHeader}
                                    </h2>

                                    <span className="acc-icon">
                                        {openIndex === index ? (
                                            <FaMinus />
                                        ) : (
                                            <FaPlus />
                                        )}
                                    </span>
                                </div>

                                <div
                                    className={`accordion-content`}
                                    style={{
                                        maxHeight: openIndex === index ? "500px" : "0px",
                                    }}
                                >
                                    <div className="accordion-body">
                                        <p>{item.accordionBody}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showButton && (
                <img
                    src={ancharTop}
                    alt=""
                    className="scrollToTop"
                    onClick={() => window.scrollTo({ top: 0 })}
                />
            )}
        </>
    );
}

export default FAQs;
