import React from 'react';
import './video-frame.scss';
import { campaignIntro, sparkles } from '../../assets';

const VideoFrame = () => {
    return (
        <div className="video-frame">
            <h4>
                Mass Outreach Meets Personal Touch <img src={sparkles} alt="" />
            </h4>
            <h3>
                Use single brackets for simple data and double brackets for dynamic, personalized content.
            </h3>
            <div className='frame'>
                <video muted autoPlay loop>
                    <source src={campaignIntro} type="video/mp4" />
                </video>
            </div>
        </div>)
}

export default VideoFrame;