const contactUs = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log(response);
    const result = await response.json();
    if (response.ok) {
      return { success: true, message: result.message };
    } else {
      return { success: false, message: result.message };
    }
  } catch (e) {
    return { success: false, message: `Something went wrong: ${e}` };
  }
};

export default contactUs;
