import React from "react";
import "./working-steps.scss";
import { workingSteps } from "../../constants";

const WorkingSteps = () => {
 
  return (
    <section className="working-steps">
      <h1> Get {" "}
        <span>Started</span>
      </h1>

      <div className="steps-container">
        {workingSteps.map((step) => (
          <div key={step.id} className="step-item">
           
            {/* Content section */}
            <div className="content-box">
              <h2>{step.title}</h2>
              <p>{step.description}</p>
            </div>

            {/* Visual section */}
            <div className="img-box">
              <img src={step.img} alt={step.title} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkingSteps;
