import React from "react";
import { Icon } from "@iconify/react";

const PlanCard = ({ item, annual }) => {
  return (
    <div
      key={item.id}
      className={`pricingcard-box ${item.name === "Pro" ? "pro" : ""}`}
    >
      <div className="card-top v-center space-between">
        <h4 className="text-darkpurple">{item.name}</h4>
        {!annual && <p className="text-darkpurple">Save $40</p>}
      </div>

      <div className="card-content">
        {item.bullets.map((e) => (
          <span key={e.id} className="v-center mt-3">
            <Icon
              color={e.enable ? "green" : "red"}
              icon={e.enable ? "entypo:check" : "ion:close"}
              width={16}
              height={16}
              className="active"
            />
            
            <label
              className={`large ${
                e.enable ? "text-darkpurple" : "text-lightpurple"
              }`}
            >
              {e.bullet}
            </label>
          </span>
        ))}
      </div>

      <div className="card-bottom-content d-flex flex-column">
        <div className="amt-txt v-center ">
          <h4 className="">${item.price}</h4>
          <label className="large">{annual ? "/year" : "/month"}</label>
        </div>

        <a href={`${process.env.REACT_APP_DASHBOARD_URL}/subscription`}>
          <button className="text-darkpurple">{item.button}</button>
        </a>
      </div>
    </div>
  );
};

export default PlanCard;
